import { getSlug } from '../helpers/get-slug'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import './link-item.scss'

const LinkItem = ({ title, slug, type }) => {
    return (
        <Link className="item" to={getSlug(type, slug)} >
            <div className="item-wrapper">
                <h4 className="item-title">{title}</h4>
                <div className="item-learn-more">Learn more</div>
            </div>
        </Link>
    )
}

LinkItem.propTypes = {
    title: PropTypes.string,
    slug: PropTypes.string,
    type: PropTypes.string,
}

export default LinkItem
