import LinkItem from './link-item'
import PropTypes from 'prop-types'
import React from 'react'

import './child-pages-content-set.scss'

const ChildPagesContentSet = (props) => {
    const { childPages } = props

    return (
        <div className="sidebar-links">
            <div className="child-pages-content-set content-set-block single-row">
                <div className="wrapper full-width">
                    <div className="content-set-block-inner constrain-width">
                        { childPages && childPages.length > 0 && childPages.map((childPage, key) => {
                            const { title, slug, internal } = childPage.node

                            return (
                                <LinkItem type={internal.type} title={title} slug={slug} key={key}/>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

ChildPagesContentSet.propTypes = {
    childPages: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            slug: PropTypes.string,
            internal: PropTypes.shape({
                type: PropTypes.string,
            }),
        }),
    })),
}

export default ChildPagesContentSet
