import ChildPagesContentSet from '../components/child-pages-content-set'
import ContentBlocks from '../components/content-blocks'
import { getSlug } from '../helpers/get-slug'
import HeaderHero from '../components/header-hero'
import Layout from '../components/layout'
import LinkItem from '../components/link-item'
import PropTypes from 'prop-types'
import React from 'react'
import SEO from '../components/seo'
import { graphql, Link } from 'gatsby'

const PageTemplate = (props) => {
    const { title, metaDescription, image, metaTags, slug, leadingParagraph, parentPage, sidebarLinks } = props.data.contentfulPage
    const { childPages, siblingPages, slug: contextSlug } = props.pageContext
    const contentBlocks = props.data.contentfulPage.contentBlocks

    const hasChildPages = Boolean(childPages && childPages.length > 0)
    const hasSiblingPages = Boolean(siblingPages && siblingPages.length > 0)

    let hasSidebar = hasSiblingPages || Boolean(parentPage) || sidebarLinks

    if (slug === 'home') {
        hasSidebar = false
    }

    return (
        <Layout>
            <SEO
                title={title}
                description={metaDescription && metaDescription.metaDescription ? metaDescription.metaDescription : null}
                imageUrl={image && image.image.fluid.src.replace(/^\/\//, 'https://')}
                tags={metaTags && metaTags.metaTags}
            />
            { slug !== 'home' &&
            <HeaderHero
                title={title}
                leadingParagraph={leadingParagraph}
                path={`/${contextSlug}`}
                parentPage={parentPage}
                image={image}
            />
            }
            { hasChildPages && <ChildPagesContentSet childPages={childPages}/>}
            { hasSidebar ?
                <div className="content-with-sidebar">
                    <div className="content-with-sidebar-main">
                        <ContentBlocks data={contentBlocks} hasSideBar={true} />
                    </div>
                    <div className="content-with-sidebar-sidebar sidebar hidden-mobile">
                        { hasSiblingPages &&
                            <div className="sidebar-section">
                                { parentPage && <h3 className="sidebar-section-title"><Link to={getSlug(parentPage.internal.type, parentPage.slug)}>{parentPage.title}</Link></h3> }
                                <ul className="sidebar-list">
                                    {
                                        siblingPages.map((page, key) => {
                                            return <li className="sidebar-list-item" key={key}>
                                                <Link className="sidebar-list-item-link" to={`/${page.node.slug}`}>{page.node.title}</Link>
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                        }
                        {
                            sidebarLinks &&
                            <div className="sidebar-links">
                                <div className="content-set-block">
                                    {
                                        sidebarLinks.map((linkData, key) => {
                                            return <LinkItem type={linkData.internal.type} title={linkData.title} slug={linkData.slug} key={key}/>
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
                :
                <ContentBlocks data={contentBlocks} />
            }
        </Layout>
    )
}

export default PageTemplate

PageTemplate.propTypes = {
    data: PropTypes.shape(({
        contentfulPage: PropTypes.shape(({
            title: PropTypes.string,
            slug: PropTypes.string,
            leadingParagraph: PropTypes.object,
            sidebarLinks: PropTypes.array,
            metaDescription: PropTypes.shape(({
                metaDescription: PropTypes.string,
            })),
            image: PropTypes.shape({
                image: PropTypes.shape({
                    fluid: PropTypes.shape(({
                        aspectRatio: PropTypes.number,
                        base64: PropTypes.string,
                        sizes: PropTypes.string,
                        src: PropTypes.string,
                        srcSet: PropTypes.string,
                    })),
                }),
            }),
            metaTags: PropTypes.shape({
                metaTags: PropTypes.string,
            }),
            contentBlocks: PropTypes.array,
            parentPage: PropTypes.shape(({
                internal: PropTypes.shape(({
                    type: PropTypes.string,
                })),
                title: PropTypes.string,
                slug: PropTypes.string,
            })),
        })),
    })),
    path: PropTypes.string,
    pageContext: PropTypes.shape(({
        childPages: PropTypes.array,
        siblingPages: PropTypes.array,
        slug: PropTypes.string,
    })),
}

export const query = graphql`
    query ContentfulPage($slug: String!) {
        contentfulPage(slug: {eq: $slug}) {
            metaDescription {
                metaDescription
            }
            metaTags {
                metaTags
            }
            image {
                image {
                    fluid(maxWidth: 960) {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                    }
                }
                altText
                caption {
                    caption
                }
            }
            contentBlocks {
                ... on ContentfulImage {
                    id
                    title
                    caption {
                        caption
                    }
                    small
                    image {
                        fluid(maxWidth: 920) {
                            base64
                            aspectRatio
                            src
                            srcSet
                            sizes
                        }
                    }
                    internal {
                        type
                    }
                }
                ... on ContentfulQuoteContentBlock {
                    id
                    internal {
                        type
                    }
                    author
                    quote {
                        quote
                    }
                }
                ... on ContentfulDownloadSingleFileContentBlock {
                    id
                    title
                    fileTitle
                    fileDescription {
                        fileDescription
                    }
                    internal {
                        type
                    }
                    file {
                        file {
                            url
                        }
                    }
                }
                ...ContentfulRichTextContentBlockFragment
                ... on ContentfulHeroGallery {
                    contentful_id
                    slide {
                        contentful_id
                        title
                        internal {
                            type
                        }
                        callToAction
                        callToActionReference {
                            slug
                            internal {
                                type
                            }
                        }
                        callToActionUrlLink
                        image {
                            image {
                                fluid(maxWidth: 920) {
                                    aspectRatio
                                    base64
                                    sizes
                                    src
                                    srcSet
                                }
                            }
                        }
                        childContentfulSlideLeadingParagraphRichTextNode {
                            json
                        }
                    }
                    internal {
                        type
                    }
                }
                ... on ContentfulContentSetContentBlock {
                    contentful_id
                    internal {
                        type
                    }
                    richTitle {
                        json
                    }
                    title
                    template
                    linkedContent {
                        ... on ContentfulPage {
                            id
                            slug
                            title
                            internal {
                                type
                            }
                            image {
                                image {
                                    fluid(maxWidth: 520) {
                                        ...GatsbyContentfulFluid_withWebp
                                    }
                                }
                            }
                            contentBlocks {
                                ...ContentfulRichTextContentBlockFragment
                            }
                        }
                        ... on ContentfulResource {
                            id
                            slug
                            title
                            image {
                                image {
                                    fluid(maxWidth: 520) {
                                        ...GatsbyContentfulFluid_withWebp
                                    }
                                }
                            }
                            category
                            tags
                            readingTime
                            internal {
                                type
                            }
                            contentBlocks {
                                ...ContentfulRichTextContentBlockFragment
                            }
                        }
                    }
                }
                ... on ContentfulTeamContentBlock {
                    id
                    title
                    internal {
                        type
                    }
                    people {
                        id
                        name
                        position
                        email
                        photo {
                            fluid(maxWidth: 500) {
                                base64
                                aspectRatio
                                src
                                sizes
                                srcSet
                            }
                        }
                        phone
                        about {
                            json
                        }
                    }
                }
                ... on ContentfulImpactMapContentBlock {
                    id
                    backgroundImage {
                        image {
                            fluid(maxWidth: 1980, maxHeight: 960, quality: 50) {
                                aspectRatio
                                base64
                                sizes
                                src
                                srcSet
                            }
                        }
                    }
                    internal {
                        type
                    }
                    callToAction
                    callToActionUrlLink
                    image {
                        altText
                        image {
                            fluid(maxWidth: 800, maxHeight: 800, resizingBehavior: SCALE) {
                                aspectRatio
                                base64
                                sizes
                                src
                                srcSet
                            }
                        }
                        title
                    }
                    leadingParagraph {
                        leadingParagraph
                    }
                    title
                    studentsImpacted
                }
                ... on ContentfulEmbedVideoBlock {
                  id
                  title
                  small
                  url
                  internal {
                    type
                  }
                }
            }
            title
            slug
            parentPage {
                title
                slug
                internal {
                    type
                }
            }
            sidebarLinks {
                ... on ContentfulPage {
                    title
                    slug
                    internal {
                        type
                    }
                }
                ... on ContentfulResource {
                    title
                    slug
                    internal {
                        type
                    }
                }
            }
            leadingParagraph {
                json
            }
        }
    }
`
